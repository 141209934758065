:root {
  /* Background colors */
  --global--dark-bg-color: #fbf2ff;
  --global--bg-color: #ffffff;
  /* Font colors */
  --global--dark-font-color: rgb(26, 26, 26);
  --global--font-color: white;
  --global--font-color--hover: lightgray;
  --global--dark-font--white--hover: lightgray;
  --global--green-font-color: oklch(0.833 0.145 321.434);
  /* Fonts */
  --global--header-font: normal 100 1em Montserrat, sans-serif;
  --global--text-font: normal 200 1em Raleway, sans-serif;
}

body {
  background-color: var(--global--bg-color);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: var(--global--text-font);
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: var(--global--header-font);
}

h2 {
  font-size: 2em;
}

section,
footer {
  display: grid;
  justify-items: center;
  text-align: center;
  padding: 30px;
}

a {
  color: inherit;
  text-decoration: underline;
}

.content {
  width: 100%;
  max-width: 500px;
}

button:focus {
  outline: 0;
}

.dark {
  background-color: var(--global--dark-bg-color);
  color: var(--global--dark-font-color);
}

.app {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 70px auto 100px;
  background-color: rgba(0, 0, 0, 0.6);
}

.app__nav--mobile {
  justify-self: end;
  padding-right: 21px;
  display: none;
}

.app__nav--desktop {
  display: flex;
  justify-self: center;
}

.app__nav {
  align-self: center;
}

.app__nav > a {
  display: inline;
  text-decoration: none;
  margin: 5px 0px 5px 20px;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  color: var(--global--font-color);
  font: var(--global--header-font);
  font-weight: 300;
  font-size: 0.9em;
}

.app__nav > a:hover {
  border: 1px solid white;
}

@media only screen and (max-width: 700px) {
  .app__nav--mobile {
    display: flex;
  }
  .app__nav--desktop {
    display: none;
  }
}

.app__title {
  padding: 5px 0px 5px 0px;
  width: 100%;
  padding: 20px;
  max-width: 400px;
  max-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
}

.app__h3 {
  font-weight: 200;
  margin: 0;
}

.app__company_name {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 3em;
  padding-bottom: 5px;
}

.app__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: var(--global--font-color);
  font-weight: 100;
}

.app__scroll {
  display: grid;
  justify-content: center;
}

.app__scroll button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  width: 40px;
  color: var(--global--font-color);
  cursor: pointer;
}

.introduction {
  display: grid;
  justify-items: center;
}

.avatar {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4) inset;
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4) inset;
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4) inset;
  position: relative;
  z-index: 2;
}

.avatar__img {
  width: 100%;
  border-radius: 50%;
  z-index: 1;
  position: relative;
}

.avatar__service-img {
  height: 150px;
  width: 150px;
}

#burger {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

#burger div {
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: center;
  height: 100%;
}

#burger span {
  display: block;
  background-color: var(--global--bg-color);
  height: 1px;
  width: 100%;
  border-radius: 2px;
  opacity: 1;
}

.modal {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: grid;
  grid-template-rows: 70px auto 70px;
  color: var(--global--font-color);
  align-items: center;
  justify-items: center;
  text-align: center;
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
  font: var(--global--header-font);
  font-size: 2em;
}

.modal nav {
  margin-top: 100px;
}

.modal--open {
  opacity: 0.97;
}

.modal--closed {
  opacity: 0;
  pointer-events: none;
}

.modal a {
  display: block;
  color: inherit;
  text-decoration: none;
  margin: 10px 0px;
}

.modal a:hover {
  color: var(--global--font-color--hover);
}

.modal__close {
  justify-self: end;
  margin-right: 22.5px;
  cursor: pointer;
  color: var(--global--green-font-color);
}

.modal__close:hover {
  color: var(--global--font-color--hover);
}

.modal__socials {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-height: 600px) {
  .modal {
    font-size: 1.5em;
  }

  .modal nav {
    margin-top: 10px;
  }

  .modal__socials {
    margin-top: 10px;
  }
}

@media only screen and (max-height: 400px) {
  .modal {
    font-size: 1.1em;
  }

  .modal nav {
    margin-top: 10px;
  }

  .modal__socials {
    margin-top: 10px;
  }
}

.socials {
  display: flex;
  justify-content: center;
  padding-left: 8px;
}

.socials svg {
  color: var(--global--green-font-color);
}

.icon {
  color: var(--global--green-font-color);
  padding: 0px 10px 0px 0px;
}

.icon:hover {
  color: var(--global--font-color--hover);
}

svg {
  fill: currentColor;
  display: inline;
}

#scroll-down {
  height: 100%;
  width: 100%;
  position: relative;
}

#scroll-down a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1.2px solid var(--global--font-color);
  border-bottom: 1.2px solid var(--global--font-color);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#scroll-down a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#scroll-down a span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
#scroll-down a span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text-input {
  display: grid;
  grid-template-rows: 40px 20px;
  justify-items: start;
  width: 100%;
}

.textarea {
  display: grid;
  grid-template-rows: 150px 20px;
  justify-items: start;
  width: 100%;
}

.form {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: auto;
  justify-items: center;
  width: 100%;
  max-width: 500px;
}

.form__input {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  font: var(--global--text-font);
  font-size: 0.9em;
  width: 100%;
  box-sizing: border-box;
}

.form__input--error {
  border-color: #dc3545;
  background-color: #e9cacd;
}

.form__textarea {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  height: 100%;
  resize: none;
  font: var(--global--text-font);
  font-size: 0.9em;
  width: 100%;
  box-sizing: border-box;
}

.form__textarea--error {
  border-color: #dc3545;
  background-color: #dab7ba;
}

.form__error {
  color: #dc3545;
  font-size: 0.8em;
}

.form__api-error {
  border: 1px solid red;
  padding: 20px;
  margin-bottom: 15px;
  color: #ff0019;
  font-size: 1em;
  border-color: #dc3545;
  background-color: #dab7ba;
}

.form__button {
  background-color: var(--global--dark-bg-color);
  color: var(--global--dark-font-color);
  border: 1px solid var(--global--dark-font-color);
  height: 40px;
  width: 150px;
  justify-self: end;
  font: var(--global--text-font);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__button:hover:enabled {
  background-color: var(--global--font-color);
  color: var(--global--dark-font-color);
  -webkit-transition: background-color 200ms linear, color 200ms linear;
  -ms-transition: background-color 200ms linear, color 200ms linear;
  transition: background-color 200ms linear, color 200ms linear;
}

.form-submitted {
  display: grid;
  align-items: center;
  min-height: 402px;
  font-size: 1.5em;
}

.form-submitted div {
  margin-bottom: 70px;
}

::placeholder {
  color: gray;
  opacity: 1;
  font: var(--global--text-font);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
  font: var(--global--text-font);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray;
  font: var(--global--text-font);
}

.price-table {
  width: 100%;
  max-width: 500px;
  border-spacing: 0px;
}
.price-table th {
  border-bottom: 1px solid #191606;
  font-size: 1.1em;
}
.price-table td {
  border-bottom: 1px solid lightgray;
}
.price-table__service {
  width: 70%;
  text-align: left;
  font-size: 1em;

  font: var(--global--header-font);
  font-weight: 300;
}
.price-table__service--special {
  padding-left: 10px;
  font-size: 0.9em;
  font-weight: 200;
}
td.price-table__service {
  padding-top: 5px;
  padding-bottom: 5px;
}
.price-table__price {
  width: 160px;
  text-align: right;
  font: var(--global--header-font);
  font-weight: 200;
  font-size: 0.9em;
}

/* Instructions component */
.instructions {
  max-width: 700px;
  text-align: start;
}

/* Palvelut section sokerointi */
.sokerointi {
  text-align: center;
  word-break: normal;
}

.sokerointi__button {
  background: none;
  border: none;
  padding: 0;
  color: var(--global--dark-font-color);
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid var(--global--dark-font-color);
  font: var(--global--text-font);
  font-size: 0.8em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(0px);
  animation: fadeIn ease 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.image-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.instagram-img-container {
  height: 100vw;
  width: 100%;
  position: relative;
}

.instagram-img-container__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.instagram-img-container__hover-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  align-content: center;
}

.instagram-img-container__hover-bg > span {
  display: none;
}

.instagram-img-container__hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.instagram-img-container__hover-bg:hover span {
  display: inline;
}

@media only screen and (min-width: 400px) {
  .image-grid {
    grid-template-columns: 1fr 1fr;
  }
  .instagram-img-container {
    height: 50vw;
  }
}

@media only screen and (min-width: 1000px) {
  .image-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .instagram-img-container {
    height: 25vw;
  }
}

.bg-image-fixed {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  display: grid;
  align-content: center;
}

.bg-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: filter 1.5s;
  filter: blur(20px);
}

.bg-image-products {
  width: 100%;
  height: 30vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  display: grid;
  align-content: center;
}

.vh-60 {
  height: 60vh;
}

@media only screen and (orientation: portrait) and (min-aspect-ratio: 2/3) {
  .bg-image-products {
    height: 60vh;
  }

  .bg-img {
    top: -50px;
  }
}

@media only screen and (orientation: landscape) {
  .bg-image-products {
    height: 80vh;
  }

  .bg-img {
    top: -70px;
  }
}

.full-width-img {
  width: 100%;
}

#palvelut h2,
#palvelut h3 {
  font-weight: 300;
  color: var(--global--green-font-color);
}
#hinnasto h2 {
  font-weight: 300;
  color: var(--global--green-font-color);
}
#yhteystiedot h2 {
  font-weight: 300;
  color: var(--global--green-font-color);
}

.service-img > img {
  margin-bottom: 50px;
  max-width: 200px;
}

.kosmetologi-kotiisi-logo {
  width: 200px;
  margin-top: 50px;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.campaign {
  padding: 0px;
  /* box-shadow: inset 0 0 250px #000000; */
}
.campaign__div {
  width: 100%;
  max-width: 800px;
  height: 100%;
}
.campaign__img {
  width: 100%;
  height: 100%;
}

.contact {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.contact__reservations:after {
  content: ' \2193';
}

@media only screen and (min-width: 500px) {
  .contact {
    text-align: left;
  }
}

.helmi-mainos {
  background-image: url('./static/jalat.jpg');
  background-size: cover;
  background-position: center;
  padding: 4rem 2rem;
}

.helmi-mainos--content {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  text-align: left;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 2rem;
}

.helmi-mainos--content > * {
  margin: 0;
}

.helmi-mainos--title {
  color: var(--global--green-font-color);
  text-align: right;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.helmi-mainos--logo {
  align-self: last baseline;
  width: 120px;
}

@media only screen and (min-width: 350px) {
  .helmi-mainos--content {
    font-size: 1.2rem;
  }

  .helmi-mainos--title {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 500px) {
  .helmi-mainos--content {
    font-size: 1.6rem;
  }

  .helmi-mainos--title {
    font-size: 2.2rem;
  }
}
